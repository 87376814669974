*,
*:before,
*:after {
  *behavior: url("js/vendor/boxsizing.htc");
  // box-sizing: border-box;
  // margin: 0;
  // padding: 0;
}

// global-reset()
// nested-reset()

// reset-html5()

// normalize-html5()
// normalize-base()
// normalize-links()
// normalize-text()
// normalize-embed()
// normalize-groups()
// normalize-forms()
// normalize-tables()
normalize-css()

body
	// reset-body()
	reset-font()
	reset-box-model()
	
// table 
// 	reset-table()
// 	td
// 		reset-table-cell()

border-box-html()
fluid-media()
print-styles()

:focus
	outline none
	
.browsehappy
	width 100%
	padding 15px
	background #333
	color #fff
	font-size 13px
	line-height 21px
	cursor pointer
	text-align center

.browsehappy a
	color #fff
	font-weight bold

.browsehappy ahover
	text-decoration none

