.section-why-crowdspot
	background linear-gradient(-45deg, rgba(#4E8146, 1), rgba(#E26F86, 1) 90%), linear-gradient(45deg, rgba(#E26E87, 1), rgba(#50834E, 1) 90%)
	color white
	
	h2
		color white
		
	a
		color white
		text-decoration underline
	
.why-crowdspot-founders
	margin auto
	
	+above('m')
		display flex
		justify-content space-around
		max-width 900px
		
	
.why-crowdspot-founder
	text-align center
	
	+above('m')
		padding-left 20px
		padding-right 20px
		max-width 400px
	
	img
		display inline
		
.crowdspot-founder__image
	margin-top 1em
	margin-bottom 1em
	width 180px
	
.crowdspot-founder__name
	fs rem(24px)
	font-weight 600
	margin-bottom 1em