.section-logos
	text-align center
	// background-image radial-gradient(0% 80%, #284EF6 0%, #FF0000 91%)
	// background-image linear-gradient(-223deg, rgba(255,255,255,0.50) 0%, rgba(0,255,39,0.50) 100%)
	
	.button
		margin 2em
	
.client-logos
	display flex
	flex-wrap wrap
	justify-content space-around
	align-items center
	
	
	
.client-logo
	padding 2em 0.5em 0.5em
	
	i
		font-size 5px
		
	+above('m')
		padding 2em
		
		i
			font-size 10px