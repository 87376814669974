*,
*:before,
*:after {
  *behavior: url("js/vendor/boxsizing.htc");
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
body {
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img,
video,
iframe {
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic;
  display: block;
}
@media print {
  body {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14pt;
    line-height: 1.45;
    font-family: Garamond, "Times New Roman", serif;
    color: #000;
    background: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    font-family: "Helvetica", Arial, sans-serif;
  }
  h1 {
    font-size: 36pt;
    line-height: 1.25em;
  }
  h2 {
    font-size: 24pt;
    line-height: 1.25em;
  }
  h3 {
    font-size: 18pt;
    line-height: 1.25em;
  }
  h4,
  h5,
  h6 {
    font-size: 14pt;
    line-height: 1.25em;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  code {
    font: 12pt Courier, 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  }
  blockquote {
    margin: 1.2em;
    padding: 1em;
    font-size: 24pt;
    line-height: 1.45833333em;
    font-style: italic;
  }
  hr {
    background-color: #ccc;
  }
  img {
    float: left;
    margin: 1em 1.5em 1.5em 0;
    max-width: 100% !important;
  }
  a img {
    border: none;
  }
  a:link,
  a:visited {
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    color: #333;
  }
  a:link[href^="http://"]:after,
  a[href^="http://"]:visited:after {
    content: " (" attr(href) ") ";
    font-size: 90%;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="http://"] {
    color: #000;
  }
  a[href$=".jpg"]:after,
  a[href$=".jpeg"]:after,
  a[href$=".gif"]:after,
  a[href$=".png"]:after {
    content: " (" attr(href) ") ";
    display: none;
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  table {
    margin: 1px;
    text-align: left;
  }
  th {
    border-bottom: 1px solid #333;
    font-weight: bold;
  }
  td {
    border-bottom: 1px solid #333;
  }
  th,
  td {
    padding: 4px 10px 4px 0;
  }
  tfoot {
    font-style: italic;
  }
  caption {
    background: #fff;
    margin-bottom: 2em;
    text-align: left;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
}
:focus {
  outline: none;
}
.browsehappy {
  width: 100%;
  padding: 15px;
  background: #333;
  color: #fff;
  font-size: 13px;
  line-height: 21px;
  cursor: pointer;
  text-align: center;
}
.browsehappy a {
  color: #fff;
  font-weight: bold;
}
.browsehappy ahover {
  text-decoration: none;
}
.sprite {
  font-size: 10px;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  top: 1px;
  display: inline-block;
}
.sprite {
  background-repeat: no-repeat;
  background-image: url("img/iconsprites/sprite.svg");
  background-size: 22.400000000000002em 273.8em;
}
.no-svg .sprite {
  background-image: url("img/iconsprites/sprite.png");
}
.sprite-circle_green {
  background-position: 0em 0em;
  width: 22.400000000000002em;
  height: 22.400000000000002em;
}
.sprite-circle_grey {
  background-position: 0em -22.400000000000002em;
  width: 22.400000000000002em;
  height: 22.400000000000002em;
}
.sprite-circle_red {
  background-position: 0em -44.800000000000004em;
  width: 22.400000000000002em;
  height: 22.400000000000002em;
}
.sprite-close-button {
  background-position: 0em -67.2em;
  width: 3.3em;
  height: 3.3em;
}
.sprite-contact-email {
  background-position: 0em -70.5em;
  width: 4.600000000000001em;
  height: 4.600000000000001em;
}
.sprite-contact-linkedin {
  background-position: 0em -75.10000000000001em;
  width: 4.600000000000001em;
  height: 4.600000000000001em;
}
.sprite-contact-twitter {
  background-position: 0em -79.7em;
  width: 4.600000000000001em;
  height: 4.600000000000001em;
}
.sprite-crowdspot-logo {
  background-position: 0em -84.30000000000001em;
  width: 17.8em;
  height: 2.8em;
}
.sprite-facebook_logo_big {
  background-position: 0em -87.10000000000001em;
  width: 2.7em;
  height: 2.6em;
}
.sprite-facebook_logo_footer {
  background-position: 0em -89.7em;
  width: 1.6em;
  height: 1.6em;
}
.sprite-facebook_logo_footer_white {
  background-position: 0em -91.30000000000001em;
  width: 1.6em;
  height: 1.6em;
}
.sprite-green_arrow {
  background-position: 0em -92.9em;
  width: 5.2em;
  height: 7.800000000000001em;
}
.sprite-grey_arrow {
  background-position: 0em -100.7em;
  width: 5.2em;
  height: 7.800000000000001em;
}
.sprite-instagram_logo_big {
  background-position: 0em -108.5em;
  width: 2.8em;
  height: 2.6em;
}
.sprite-instagram_logo_footer {
  background-position: 0em -111.10000000000001em;
  width: 1.7em;
  height: 1.6em;
}
.sprite-instagram_logo_footer_white {
  background-position: 0em -112.7em;
  width: 1.7em;
  height: 1.6em;
}
.sprite-instagram_logo_posts {
  background-position: 0em -114.30000000000001em;
  width: 3.8em;
  height: 3.6em;
}
.sprite-linkedin {
  background-position: 0em -117.9em;
  width: 1.527em;
  height: 1.5651em;
}
.sprite-logo-cityofmelbourne {
  background-position: 0em -119.5em;
  width: 3.3em;
  height: 3.1em;
}
.sprite-logo-cityofportphillip {
  background-position: 0em -122.60000000000001em;
  width: 3.9em;
  height: 3.9em;
}
.sprite-logo-facebook {
  background-position: 0em -126.5em;
  width: 3em;
  height: 3.1em;
}
.sprite-logo-instagram {
  background-position: 0em -129.6em;
  width: 3em;
  height: 3em;
}
.sprite-logo-linkedin {
  background-position: 0em -132.6em;
  width: 3em;
  height: 3em;
}
.sprite-logo-parksvictoria {
  background-position: 0em -135.6em;
  width: 4.100000000000001em;
  height: 2.6em;
}
.sprite-logo-plan {
  background-position: 0em -138.20000000000002em;
  width: 7.5em;
  height: 2.9em;
}
.sprite-logo-tac {
  background-position: 0em -141.1em;
  width: 5.300000000000001em;
  height: 2.5em;
}
.sprite-logo-theuniversityofmelbourne {
  background-position: 0em -143.6em;
  width: 3.9em;
  height: 3.7em;
}
.sprite-logo-twitter {
  background-position: 0em -147.3em;
  width: 3em;
  height: 2.5em;
}
.sprite-logo-victoria {
  background-position: 0em -149.8em;
  width: 5.5em;
  height: 3.1em;
}
.sprite-logo-vta {
  background-position: 0em -152.9em;
  width: 5.300000000000001em;
  height: 3.1em;
}
.sprite-post-arrow-left {
  background-position: 0em -156em;
  width: 1em;
  height: 1.5em;
}
.sprite-post-facebook-share {
  background-position: 0em -157.5em;
  width: 1.6em;
  height: 1.6em;
}
.sprite-projects-comments-icon {
  background-position: 0em -159.10000000000002em;
  width: 6.9em;
  height: 6.4em;
}
.sprite-projects-spots-icon {
  background-position: 0em -165.5em;
  width: 3.9em;
  height: 5.800000000000001em;
}
.sprite-projects-supports-icon {
  background-position: 0em -171.3em;
  width: 5.5em;
  height: 8.5em;
}
.sprite-red_arrow {
  background-position: 0em -179.8em;
  width: 5.2em;
  height: 7.800000000000001em;
}
.sprite-slider-arrow-left {
  background-position: 0em -187.60000000000002em;
  width: 6.9em;
  height: 10.100000000000001em;
}
.sprite-slider-arrow-left-dark {
  background-position: 0em -197.70000000000002em;
  width: 5.100000000000001em;
  height: 5em;
}
.sprite-slider-arrow-left-mobile {
  background-position: 0em -202.70000000000002em;
  width: 5em;
  height: 6.5em;
}
.sprite-slider-arrow-right {
  background-position: 0em -209.20000000000002em;
  width: 6.9em;
  height: 10.100000000000001em;
}
.sprite-slider-arrow-right-dark {
  background-position: 0em -219.3em;
  width: 5.100000000000001em;
  height: 5em;
}
.sprite-slider-arrow-right-mobile {
  background-position: 0em -224.3em;
  width: 5em;
  height: 6.4em;
}
.sprite-slider-dot {
  background-position: 0em -230.70000000000002em;
  width: 1em;
  height: 1em;
}
.sprite-slider-dot-current {
  background-position: 0em -231.70000000000002em;
  width: 1em;
  height: 1em;
}
.sprite-solutions-asset-monitoring {
  background-position: 0em -232.70000000000002em;
  width: 4.2em;
  height: 5.2em;
}
.sprite-solutions-community-engagement {
  background-position: 0em -237.9em;
  width: 7.7em;
  height: 5.7em;
}
.sprite-solutions-environmental-management {
  background-position: 0em -243.60000000000002em;
  width: 5.7em;
  height: 5.600000000000001em;
}
.sprite-solutions-internation-development {
  background-position: 0em -249.20000000000002em;
  width: 5.5em;
  height: 7.9em;
}
.sprite-solutions-media {
  background-position: 0em -257.1em;
  width: 5.9em;
  height: 5.2em;
}
.sprite-solutions-urban-planning {
  background-position: 0em -262.3em;
  width: 9.1em;
  height: 5.4em;
}
.sprite-x_close_desktop {
  background-position: 0em -267.7em;
  width: 4em;
  height: 4em;
}
.sprite-x_close_mobile {
  background-position: 0em -271.7em;
  width: 2.1em;
  height: 2.1em;
}
.hidden {
  display: none;
}
.hide-mobile {
  display: none;
}
@media only screen and (min-width: 600px) {
  .hide-mobile {
    display: block;
  }
}
.show-only-mobile {
  display: block;
}
@media only screen and (min-width: 600px) {
  .show-only-mobile {
    display: none;
  }
}
.show-only-mobile-inline {
  display: inline;
}
@media only screen and (min-width: 600px) {
  .show-only-mobile-inline {
    display: none;
  }
}
.show-only-mobile-inline-block {
  display: inline-block;
}
@media only screen and (min-width: 600px) {
  .show-only-mobile-inline-block {
    display: none;
  }
}
.show-tablet {
  display: none;
}
@media only screen and (min-width: 600px) {
  .show-tablet {
    display: block;
  }
}
.show-tablet-inline {
  display: none;
}
@media only screen and (min-width: 600px) {
  .show-tablet-inline {
    display: inline;
  }
}
.show-tablet-inline-block {
  display: none;
}
@media only screen and (min-width: 600px) {
  .show-tablet-inline-block {
    display: inline-block;
  }
}
.show-desktop {
  display: none;
}
@media only screen and (min-width: 800px) {
  .show-desktop {
    display: block;
  }
}
.show-desktop-inline {
  display: none;
}
@media only screen and (min-width: 800px) {
  .show-desktop-inline {
    display: inline;
  }
}
.show-desktop-inline-block {
  display: none;
}
@media only screen and (min-width: 800px) {
  .show-desktop-inline-block {
    display: inline-block;
  }
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
html {
  font-family: 'proxima-nova', sans-serif;
  font-weight: 400;
  color: #666;
  font-size: 12px;
  line-height: 1.7em;
}
@media (min-width: 400px) {
  html {
    font-size: calc( 12px + (18 - 12) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  html {
    font-size: 18px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
}
p,
blockquote,
pre,
address,
dl,
ol,
ul,
table {
  margin-bottom: 1.7em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'proxima-nova', sans-serif;
  font-weight: 600;
  color: #666;
  clear: both;
}
h1 {
  font-size: 27.85438995234061px;
  margin-top: 0.915475084668197em;
  line-height: 1.098570101601837em;
  margin-bottom: 0.183095016933639em;
}
@media (min-width: 400px) {
  h1 {
    font-size: calc( 27.85438995234061px + (41.78158492851092 - 27.85438995234061) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  h1 {
    font-size: 41.78158492851092px;
    margin-top: 0.915475084668197em;
    line-height: 1.098570101601837em;
    margin-bottom: 0.183095016933639em;
  }
}
h2 {
  font-size: 23.53700340860508px;
  margin-top: 1.083400446408452em;
  line-height: 1.300080535690142em;
  margin-bottom: 0.244297889923526em;
}
@media (min-width: 400px) {
  h2 {
    font-size: calc( 23.53700340860508px + (35.305505112907625 - 23.53700340860508) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  h2 {
    font-size: 35.305505112907625px;
    margin-top: 1.083400446408452em;
    line-height: 1.300080535690142em;
    margin-bottom: 0.235091956376248em;
  }
}
h3 {
  font-size: 19.888804974891777px;
  margin-top: 1.282128314506174em;
  line-height: 1.538553977407409em;
  margin-bottom: 0.295104557368492em;
}
@media (min-width: 400px) {
  h3 {
    font-size: calc( 19.888804974891777px + (29.833207462337665 - 19.888804974891777) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  h3 {
    font-size: 29.833207462337665px;
    margin-top: 1.282128314506174em;
    line-height: 1.538553977407409em;
    margin-bottom: 0.282211592546073em;
  }
}
h4 {
  font-size: 16.806071548796314px;
  margin-top: 1.517308784861526em;
  line-height: 1.820770541833831em;
  margin-bottom: 0.357631779292728em;
}
@media (min-width: 400px) {
  h4 {
    font-size: calc( 16.806071548796314px + (25.209107323194473 - 16.806071548796314) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  h4 {
    font-size: 25.209107323194473px;
    margin-top: 1.517308784861526em;
    line-height: 1.820770541833831em;
    margin-bottom: 0.33957510518592em;
  }
}
h5 {
  font-size: 14.201156945318074px;
  margin-top: 1.795628349027365em;
  line-height: 2.154754018832838em;
  margin-bottom: 0.359125669805473em;
}
@media (min-width: 400px) {
  h5 {
    font-size: calc( 14.201156945318074px + (21.30173541797711 - 14.201156945318074) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  h5 {
    font-size: 21.30173541797711px;
    margin-top: 1.795628349027366em;
    line-height: 2.154754018832838em;
    margin-bottom: 0.359125669805473em;
  }
}
h6 {
  font-size: 12px;
  margin-top: 2.125em;
  line-height: 2.55em;
  margin-bottom: 0.602083333333333em;
}
@media (min-width: 400px) {
  h6 {
    font-size: calc( 12px + (18 - 12) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  h6 {
    font-size: 18px;
    margin-top: 2.125em;
    line-height: 2.55em;
    margin-bottom: 0.460416666666667em;
  }
}
blockquote {
  font-style: italic;
}
blockquote cite {
  display: block;
  font-style: normal;
}
pre {
  padding: 0.85em;
  margin-bottom: 1.7em;
}
pre code {
  padding: 0;
}
code {
  font-family: 'Courier New', 'Courier', 'Lucida Sans Typewriter', 'Lucida Typewriter', 'monospace';
  padding: 0.085em 0.255em;
  line-height: 0;
}
big,
small,
sub,
sup {
  line-height: 0;
}
abbr,
acronym {
  border-bottom: 1px dotted currentColor;
  cursor: help;
}
address {
  font-style: normal;
}
dt {
  color: #666;
  font-weight: bold;
}
ul {
  padding-left: 1.1em;
}
ol {
  padding-left: 1.4em;
}
fieldset {
  padding: 0.85em 1.7em 1.7em;
  border-width: 1px;
  border-style: solid;
  max-width: 100%;
  margin-bottom: 1.794444444444445em;
}
@media (min-width: 1440px) {
  fieldset {
    margin-bottom: 2.006944444444444em;
  }
}
fieldset button,
fieldset input[type="submit"] {
  margin-bottom: 0;
}
legend {
  color: #666;
  font-weight: bold;
}
label {
  display: block;
  padding-bottom: 0.2125em;
  margin-bottom: -0.188888888888889em;
}
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  display: block;
  max-width: 100%;
  padding: 0.425em;
  font-size: 12px;
  margin-bottom: 1.180555555555556em;
}
@media (min-width: 400px) {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    font-size: calc( 12px + (18 - 12) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    font-size: 18px;
    margin-bottom: 0.507638888888889em;
  }
}
input[type="submit"],
button {
  display: block;
  cursor: pointer;
  font-size: 12px;
  padding: 0.425em 1.7em;
  margin-bottom: 1.133333333333333em;
}
@media (min-width: 400px) {
  input[type="submit"],
  button {
    font-size: calc( 12px + (18 - 12) * ((100vw - 400px) / (1440 - 400)) );
  }
}
@media (min-width: 1440px) {
  input[type="submit"],
  button {
    font-size: 18px;
    margin-bottom: 1.275em;
  }
}
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 2.172222222222222em;
}
@media (min-width: 1440px) {
  table {
    margin-bottom: 2.030555555555555em;
  }
}
th {
  text-align: left;
  color: #666;
  padding: 0.2125em 0.85em;
}
@media (min-width: 1440px) {
  th {
    padding: 0.425em 0.85em;
  }
}
td {
  padding: 0.2125em 0.85em;
}
@media (min-width: 1440px) {
  td {
    padding: 0.425em 0.85em;
  }
}
.container {
  lost-utility: clearfix;
  lost-center: 1200px 20px;
}
@media only screen and (min-width: 600px) {
  .column {
    lost-column: 1/2;
  }
}
@media only screen and (min-width: 600px) {
  .column-offset {
    lost-offset: -1/2;
  }
}
@media only screen and (min-width: 600px) {
  .column-3 {
    lost-column: 1/3;
  }
}
.waffle-grid {
  margin: 30px 0;
  lost-utility: clearfix;
}
.waffle-grid-item {
  padding: 20px;
  background: #40d451;
  background: #29b83a linear-gradient(180deg, #40d451, #29b83a) repeat-x;
  lost-waffle: 1/3;
  text-align: center;
}
.waffle-grid-item.waffle-grid-item--nopadding {
  lost-waffle: 1/3 3 0;
}
.column-no-gutters {
  background: #e44921;
  background: #bc3917 linear-gradient(180deg, #e44921, #bc3917) repeat-x;
  height: 300px;
}
@media only screen and (min-width: 600px) {
  .column-no-gutters {
    lost-column: 1/2 2 0;
  }
}
.grid-row {
  lost-utility: clearfix;
}
.align-parent {
  lost-align: center;
  width: 100%;
  height: 100vh;
  background: #e44921;
  background: #bc3917 linear-gradient(180deg, #e44921, #bc3917) repeat-x;
  color: #fff;
}
@media only screen and (min-width: 600px) {
  .align-child {
    width: 500px;
  }
}
.row-container {
  height: 100vh;
}
.row {
  lost-align: center;
  lost-utility: clearfix;
  lost-row: 1/3 0;
  background: #2ecc40;
  background: #0074d9 linear-gradient(180deg, #2ecc40, #0074d9) repeat-x;
}
.grid-asymetrical {
  width: 100%;
  lost-utility: clearfix;
}
.grid-asymetrical .grid-item {
  text-align: center;
  height: 100px;
  background: #d13f19;
  lost-column: 1/4 0;
  height: 50vh;
}
.grid-asymetrical .grid-item:nth-child(2) {
  lost-column: 1/2 0;
}
.grid-move .grid-item {
  background: #d13f19;
  height: 100px;
  lost-column: 1/3;
}
.grid-move .move-grid-item {
  lost-move: 1/3;
}
::selection {
  background-color: #d12325;
  color: #fff;
}
.bubble {
  color: #fff;
  background: #efefef;
  background: #e7e7e7 linear-gradient(180deg, #efefef, #e7e7e7) repeat-x;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #d6d6d6;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.05);
  position: relative;
}
.bubble:after {
  content: "";
  display: block;
  clear: both;
}
.bubble:before {
  margin-top: auto;
  margin-left: auto;
  width: 0;
  height: 0;
  border-left: 11.3px solid transparent;
  border-right: 11.3px solid transparent;
  border-top: 11.3px solid #d0d0d0;
  position: absolute;
  bottom: -11.3px;
  left: 14px;
  display: block;
}
.bubble:after {
  margin-top: auto;
  margin-left: auto;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #e7e7e7;
  position: absolute;
  bottom: -10px;
  left: 15px;
  display: block;
}
code {
  padding: 3px 4px;
  color: #0074d9;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e8;
  border-radius: 3px;
  font-family: Menlo, Monaco, 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
}
pre {
  display: block;
  padding: 7px;
  background-color: #f5f5f5;
  border: 1px solid #e1e1e8;
  border-radius: 3px;
  white-space: pre-wrap;
  word-break: break-all;
  font-family: Menlo, Monaco, 'Bitstream Vera Sans Mono', Consolas, Courier, monospace;
  line-height: 160%;
}
.table-layout {
  display: table;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  width: 100%;
}
.table-layout > * {
  display: table-cell;
}
table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 18px;
  border: 1px solid #ddd;
  border-collapse: separate;
  *border-collapse: collapsed;
  border-radius: 4px;
}
table th,
table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #ddd;
}
table th {
  font-weight: bold;
}
table thead th {
  vertical-align: bottom;
}
table thead:first-child tr th,
table thead:first-child tr td {
  border-top: 0;
}
table tbody + tbody {
  border-top: 2px solid #ddd;
}
table th + th,
table td + td,
table th + td,
table td + th {
  border-left: 1px solid #ddd;
}
table thead:first-child tr:first-child th,
table tbody:first-child tr:first-child th,
table tbody:first-child tr:first-child td {
  border-top: 0;
}
table thead:first-child tr:first-child th:first-child,
table tbody:first-child tr:first-child td:first-child {
  border-radius: 4px 0 0 0;
}
table thead:first-child tr:first-child th:last-child,
table tbody:first-child tr:first-child td:last-child {
  border-radius: 0 4px 0 0;
}
table thead:last-child tr:last-child th:first-child,
table tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}
table thead:last-child tr:last-child th:last-child,
table tbody:last-child tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}
table tbody tr:nth-child(odd) td,
table tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}
table tbody tr:hover td,
table tbody tr:hover th {
  background-color: #f5f5f5;
}
.icons-container {
  border: 2px #ff851b solid;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(34,34,34,0.5) inset;
  background: #ffdec2;
  background: #ffc28f linear-gradient(180deg, #ffdec2, #ffc28f) repeat-x;
  lost-utility: clearfix;
}
.icons-container .icon {
  text-align: center;
  padding: 20px 0;
  lost-column: 1/2;
}
@media only screen and (min-width: 400px) {
  .icons-container .icon {
    padding: 40px 0;
    lost-column: 1/3;
  }
}
@media only screen and (min-width: 800px) {
  .icons-container .icon {
    lost-column: 1/6;
  }
}
h2 {
  text-align: center;
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1em;
}
@media only screen and (min-width: 600px) {
  h2 {
    margin-bottom: 57px;
  }
}
.gradientAnimatedBird {
  margin-bottom: -20px;
  margin-left: 1em;
}
.title-2 {
  font-weight: 600;
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 1.5em;
}
@media only screen and (min-width: 600px) {
  .title-2 {
    margin-bottom: 45px;
  }
}
p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.25;
}
a {
  color: #d12325;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='time'],
input[type='week'],
textarea {
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  border: solid 1px #bbb;
  border-radius: 0.2em;
  outline: none;
  padding: 0.45em;
  background: #fff;
  color: #555;
  width: 250px;
  text-shadow: 0 0 1px rgba(255,255,255,0.1);
  transition: all .3s ease;
}
input[type='email']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='url']:focus,
input[type='color']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
textarea:focus {
  box-shadow: 0 0 5px rgba(127,219,255,0.7);
  border: 1px solid #95d1e9;
  outline: none;
}
form .field {
  max-width: 100%;
}
form .field.error {
  color: #d13f19;
}
form .field.error input {
  color: #d13f19;
  border-color: #d13f19;
}
form .field.error input:focus {
  box-shadow: 0 0 5px rgba(209,63,25,0.7);
  border: 1px solid #b15239;
  outline: none;
}
form .field.success {
  color: #2ecc40;
}
form .field.success input {
  color: #2ecc40;
  border-color: #2ecc40;
}
form .field.success input:focus {
  box-shadow: 0 0 5px rgba(46,204,64,0.7);
  border: 1px solid #4ab055;
  outline: none;
}
form .field.warning {
  color: #d9bb00;
}
form .field.warning input {
  color: #d9bb00;
  border-color: #ffdc00;
}
form .field.warning input:focus {
  box-shadow: 0 0 5px rgba(255,220,0,0.7);
  border: 1px solid #d2bc2d;
  outline: none;
}
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  border: solid 1px #bbb;
  border-radius: 0.2em;
  outline: none;
  padding: 0.45em;
  background: #fff;
  color: #555;
  width: 250px;
  text-shadow: 0 0 1px rgba(255,255,255,0.1);
  transition: all .3s ease;
  display: block;
  margin-bottom: 1em;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
  box-shadow: 0 0 5px rgba(127,219,255,0.7);
  border: 1px solid #95d1e9;
  outline: none;
}
input[type="text"].error,
input[type="email"].error,
input[type="tel"].error,
textarea.error {
  color: #d13f19;
}
input[type="text"].error input,
input[type="email"].error input,
input[type="tel"].error input,
textarea.error input {
  color: #d13f19;
  border-color: #d13f19;
}
input[type="text"].error input:focus,
input[type="email"].error input:focus,
input[type="tel"].error input:focus,
textarea.error input:focus {
  box-shadow: 0 0 5px rgba(209,63,25,0.7);
  border: 1px solid #b15239;
  outline: none;
}
input[type="text"].success,
input[type="email"].success,
input[type="tel"].success,
textarea.success {
  color: #2ecc40;
}
input[type="text"].success input,
input[type="email"].success input,
input[type="tel"].success input,
textarea.success input {
  color: #2ecc40;
  border-color: #2ecc40;
}
input[type="text"].success input:focus,
input[type="email"].success input:focus,
input[type="tel"].success input:focus,
textarea.success input:focus {
  box-shadow: 0 0 5px rgba(46,204,64,0.7);
  border: 1px solid #4ab055;
  outline: none;
}
input[type="text"].warning,
input[type="email"].warning,
input[type="tel"].warning,
textarea.warning {
  color: #d9bb00;
}
input[type="text"].warning input,
input[type="email"].warning input,
input[type="tel"].warning input,
textarea.warning input {
  color: #d9bb00;
  border-color: #ffdc00;
}
input[type="text"].warning input:focus,
input[type="email"].warning input:focus,
input[type="tel"].warning input:focus,
textarea.warning input:focus {
  box-shadow: 0 0 5px rgba(255,220,0,0.7);
  border: 1px solid #d2bc2d;
  outline: none;
}
label {
  line-height: 1.5em;
  display: inline-block;
  display: none;
}
label.label--block {
  line-height: 1.5em;
  display: block;
}
.mailchimp-form {
  position: relative;
}
.mc-text-field {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.mc-label {
  margin-bottom: 1em;
}
.button {
  font-size: 13px;
  padding: 10px 22px;
  border-radius: 3px;
  background-color: #f6f6f6;
  color: rgba(0,0,0,0.7);
  cursor: pointer;
  font-weight: bold;
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none;
  -webkit-touch-callout: none;
  user-select: none;
  box-shadow: 1px 1px 2px rgba(34,34,34,0.2);
  transition: box-shadow ease 0.3s;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600;
}
.button:hover,
.button:focus {
  background-color: #eaeaea;
  color: rgba(0,0,0,0.7);
  border: none;
}
.button:active {
  background-color: #ddd;
}
.button:hover {
  background-color: #fff;
  box-shadow: 0 0 4px rgba(34,34,34,0.2);
  text-decoration: none;
}
.button--glossy {
  font-size: 13px;
  padding: 10px 22px;
  border-radius: 3px;
  background: #0084f6;
  background: #0068c3 linear-gradient(180deg, #0084f6, #0068c3) repeat-x;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  border: 1px solid #0068c3;
  color: #fff;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.3);
  cursor: pointer;
  font-weight: bold;
  font-family: "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -webkit-touch-callout: none;
  user-select: none;
  box-shadow: 0 0 1px transparent;
  transition: all ease 0.2s;
}
.button--glossy:hover,
.button--glossy:focus {
  background-position: 0 -10px;
  color: #f7f7f7;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
}
.button--glossy:active {
  box-shadow: inset 0 1px 5px rgba(0,87,163,0.6);
  text-shadow: 0 -1px 1px rgba(0,0,0,0.2);
  border-color: #005fb2;
}
.button--glossy:hover {
  position: relative;
  z-index: 10;
  transform: scale(1.2);
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  transform: scale(1.2) rotate(3deg);
}
.button--block {
  display: block;
}
.pace {
  pointer-events: none;
  user-select: none;
}
body {
  overflow: hidden;
}
.fade-in-on-load {
  opacity: 0;
  transition: opacity 1.5s;
}
.pace-done {
  overflow: scroll;
}
.pace-done .fade-in-on-load {
  opacity: 1;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #f00;
  position: fixed;
  z-index: 3000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 5px;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-loading .slick-list {
  background: #fff url("./img/ajax-loader.gif") center center no-repeat;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: '\2190';
}
[dir="rtl"] .slick-prev:before {
  content: '\2192';
}
.slick-next {
  right: -25px;
}
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: '\2192';
}
[dir="rtl"] .slick-next:before {
  content: '\2190';
}
.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '\2022';
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #222;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #222;
  opacity: 0.75;
}
.slick-slider-container {
  position: relative;
}
.slick-slider {
  opacity: 0;
}
@media only screen and (min-width: 1050px) {
  .slick-slider .slide {
    padding-left: 70px;
    padding-right: 70px;
  }
}
.slick-slider.slick-initialized {
  opacity: 1;
}
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: auto;
  right: 10px;
  width: auto;
  height: auto;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.5s;
  z-index: 1000;
}
.slick-prev:hover,
.slick-next:hover {
  opacity: 1;
}
.slick-prev:before,
.slick-next:before {
  display: none;
  color: #d13f19;
}
.slick-prev {
  left: 10px;
  right: auto;
}
.site-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(255,255,255,0.95);
  padding: 1em 0;
  box-shadow: 0 0 20px 0 rgba(34,34,34,0.2);
  opacity: 0;
}
@media only screen and (min-width: 600px) {
  .site-header__inner {
    display: flex;
  }
}
.site-header__logo {
  flex: auto;
}
.site-header__mobile-toggle {
  font-size: 40px;
  font-size: 2.5rem;
  position: absolute;
  right: 10px;
  top: 15px;
}
@media only screen and (min-width: 600px) {
  .site-header__mobile-toggle {
    display: none;
  }
}
.site-header__menu-items {
  flex: auto;
  justify-content: space-between;
  max-width: 540px;
}
@media only screen and (max-width: 600px) {
  .site-header__menu-items {
    width: 100%;
    display: none;
    background-color: #fff;
    max-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .site-header__menu-items.mobile-menu-open {
    display: flex;
  }
}
@media only screen and (min-width: 600px) {
  .site-header__menu-items {
    display: flex;
  }
}
.site-header__menu-items a {
  color: #4a4f4c;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: 2px solid transparent;
  transition: 0.5s border ease;
}
.site-header__menu-items a:hover,
.site-header__menu-items a.current-section-in-view {
  border-bottom-color: #4a4f4c;
}
.site-header__menu-items__menu-item {
  align-self: flex-end;
}
@media only screen and (max-width: 600px) {
  .site-header__menu-items__menu-item {
    margin: 10px;
  }
}
.site-footer {
  background-image: url("img/footerbackground.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 3em;
  padding-bottom: 4em;
  color: #4a4f4c;
}
.site-footer h3 {
  color: #4a4f4c;
}
.site-footer a {
  color: #4a4f4c;
  opacity: 0.7;
  transition: 0.5s opacity ease;
}
.site-footer a:hover {
  opacity: 1;
}
@media only screen and (min-width: 600px) {
  .footer-column {
    lost-column: 5/12;
    lost-offset: -1/12;
  }
  .footer-column.footer-column--right {
    lost-column: 4/12;
    text-align: right;
  }
}
.social-icons {
  margin-top: -1em;
  display: flex;
  justify-content: space-between;
  max-width: 150px;
}
@media only screen and (min-width: 600px) {
  .social-icons {
    margin-right: 0;
    margin-left: auto;
  }
}
.section {
  padding-top: 4em;
  padding-bottom: 4em;
}
@media only screen and (min-width: 600px) {
  .section {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
.section__subtitle {
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .section__subtitle {
    lost-column: 8/12;
    lost-offset: -2/12;
  }
}
.section__intro {
  margin-bottom: 2em;
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .section__intro {
    lost-column: 10/12;
    lost-offset: -1/12;
  }
}
#map {
  display: block;
}
#map .roads,
#map .features,
#map .mainroad,
#map .marker,
#map .visualise,
#map .decisionbird,
#map #logotype,
#map .logobird,
#map .mapgradient,
#map .introTitles {
  opacity: 0;
}
.safari #map .roads,
.mobile #map .roads,
.safari #map .features,
.mobile #map .features,
.safari #map .mainroad,
.mobile #map .mainroad,
.safari #map #logotype,
.mobile #map #logotype,
.safari #map .logobird,
.mobile #map .logobird,
.safari #map .mapgradient,
.mobile #map .mapgradient {
  opacity: 1;
}
.main-intro {
  position: relative;
}
.main-intro .section__subtitle {
  display: block;
  position: absolute;
  opacity: 0;
  bottom: 100%;
}
.main-intro .section__subtitle .title-2 {
  text-align: center;
  font-size: 16px;
  font-size: 1rem;
}
@media only screen and (min-width: 320px) {
  .main-intro .section__subtitle {
    bottom: 5%;
    left: 0;
    right: 0;
  }
}
@media only screen and (min-width: 600px) {
  .main-intro .section__subtitle {
    bottom: 8%;
  }
  .main-intro .section__subtitle .title-2 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1050px) {
  .main-intro .section__subtitle {
    bottom: 22%;
  }
}
.section-logos {
  text-align: center;
}
.section-logos .button {
  margin: 2em;
}
.client-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.client-logo {
  padding: 2em 0.5em 0.5em;
}
.client-logo i {
  font-size: 5px;
}
@media only screen and (min-width: 600px) {
  .client-logo {
    padding: 2em;
  }
  .client-logo i {
    font-size: 10px;
  }
}
.section-testimonials {
  background-image: url("img/background-city-02.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 3em;
  padding-bottom: 3em;
  color: #fff;
}
.section-testimonials h2 {
  margin-top: 0;
}
.section-testimonials h2 .gradientAnimatedBird {
  margin-left: 0;
}
.section-testimonials .slick-slider {
  margin-bottom: 0;
}
.section-testimonials .slick-slider .slick-track {
  display: flex;
  align-items: center;
}
.testimonial {
  text-align: center;
}
@media only screen and (min-width: 800px) {
  .testimonial {
    lost-column: 10/12;
    lost-offset: -1/12;
  }
}
@media only screen and (min-width: 1050px) {
  .testimonial {
    lost-column: 8/12;
    lost-offset: -2/12;
  }
}
.section-how-it-works {
  text-align: center;
  background-color: #f4f4f4;
  padding-top: 0em;
}
.section-how-it-works .gradientAnimatedBird {
  width: 28px;
  margin-bottom: -30px;
  margin-left: 0.5em;
}
@media only screen and (min-width: 600px) {
  .section-how-it-works .gradientAnimatedBird {
    width: 47px;
    margin-bottom: -20px;
    margin-left: 1em;
  }
}
@media only screen and (min-width: 600px) {
  .section-how-it-works {
    padding-top: 60px;
  }
}
.slick-slider--how-it-works img {
  margin-left: auto;
  margin-right: auto;
}
.how-it-works-tab-icon__hover {
  opacity: 1;
  transition: opacity 0.5s ease;
  fill: #808080;
}
.how-it-works-tabs {
  lost-utility: clearfix;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
}
.how-it-works-mobile-tab {
  margin-top: 1em;
  margin-bottom: 2em;
  opacity: 1;
}
.how-it-works-mobile-tab .how-it-works-tab-icon__hover {
  opacity: 0;
}
.how-it-works-mobile-tab .how-it-works-tab-icon {
  width: 50px;
  height: 50px;
}
.how-it-works-tab {
  opacity: 0.55;
  transition: opacity 0.5s ease;
  cursor: pointer;
}
@media only screen and (min-width: 600px) {
  .how-it-works-tab {
    lost-column: 1/3;
  }
}
.how-it-works-tab:hover,
.how-it-works-tab.active {
  opacity: 1;
}
.how-it-works-tab:hover .how-it-works-tab-icon__hover,
.how-it-works-tab.active .how-it-works-tab-icon__hover {
  opacity: 0;
}
.slick-slider--how-it-works__tab-title {
  font-weight: 600;
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 1em;
}
.how-it-works-tab-icon {
  margin-top: 1em;
  margin-bottom: 1em;
}
.how-it-works-grid {
  margin: 4em auto 0em;
  box-shadow: 0 0 10px 0 rgba(34,34,34,0.1);
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 800px) {
  .how-it-works-grid {
    width: 80%;
  }
}
.how-it-works-grid__item {
  width: 100%;
  padding: 1.5em 1em 2em;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(34,34,34,0.2);
}
@media only screen and (min-width: 400px) {
  .how-it-works-grid__item {
    width: 50%;
  }
}
@media only screen and (min-width: 600px) {
  .how-it-works-grid__item {
    width: 33.333333333333336%;
  }
}
.how-it-works-grid__item__icon {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-crowdsport-projects {
  background-image: linear-gradient(180deg, #4a4f4c 0%, #4a4f4c 45%, #cfd2c1 45%, #cfd2c1 100%);
  padding-top: 1em;
}
@media only screen and (min-width: 600px) {
  .section-crowdsport-projects {
    padding-top: 4em;
    background-image: linear-gradient(180deg, #4a4f4c 0%, #4a4f4c 63%, #cfd2c1 63%, #cfd2c1 100%);
  }
}
.section-crowdsport-projects .gradientAnimatedBird {
  width: 47px;
  margin-bottom: -18px;
  margin-left: 0.5em;
}
@media only screen and (min-width: 600px) {
  .section-crowdsport-projects .gradientAnimatedBird {
    width: 67px;
    margin-bottom: -15px;
    margin-left: 1em;
  }
}
.section-crowdsport-projects h2 {
  color: #fff;
}
.projects-tabs {
  lost-utility: clearfix;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
  display: flex;
}
.projects-tab {
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.25;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  color: #fff;
  padding: 5px;
  flex: 1 1 0;
}
.projects-tab.active,
.projects-tab:hover {
  opacity: 1;
  cursor: pointer;
}
.projects-tab.active .projects-tab-images,
.projects-tab:hover .projects-tab-images {
  border: 4px solid #fff;
}
.slick-slider--projects__tab-title {
  display: none;
}
@media only screen and (min-width: 600px) {
  .slick-slider--projects__tab-title {
    display: block;
  }
}
.projects-tab-images {
  max-width: 105px;
  width: 100%;
  margin: auto;
  border-radius: 50%;
  box-shadow: 2px 2px 4px 0 rgba(34,34,34,0.18);
  margin-bottom: 1em;
  border: 4px solid transparent;
  transition: border 0.5s ease;
}
.slick-slider--projects {
  margin-bottom: 0;
}
.project-slide__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.project-slide__image {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 600px) {
  .project-slide__image {
    margin-bottom: 3em;
  }
}
@media only screen and (min-width: 600px) {
  .project-slide__details {
    lost-column: 3/6;
  }
}
.project-slide__title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 1.5em;
  display: none;
}
.project-slide__title.project-slide__title--mobile {
  color: #fff;
  display: block;
}
@media only screen and (min-width: 600px) {
  .project-slide__title {
    display: block;
  }
  .project-slide__title.project-slide__title--mobile {
    display: none;
  }
}
.project-slide__stat {
  lost-column: 1/3;
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .project-slide__stat {
    lost-column: 1/6;
  }
}
.project-slide__stat__icon {
  height: 50px;
  position: relative;
  margin-bottom: 0.5em;
}
@media only screen and (min-width: 600px) {
  .project-slide__stat__icon {
    height: 100px;
  }
}
.project-slide__stat__icon i {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 5px;
}
@media only screen and (min-width: 600px) {
  .project-slide__stat__icon i {
    font-size: 10px;
  }
}
.project-slide__stat__label {
  color: #d12325;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 0.3em;
}
.project-slide__stat__value {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
}
.project-slide__project-link {
  lost-utility: clearfix;
}
.slick-slider--project-sub-slider {
  margin-bottom: 1.5em;
}
.slick-dots {
  bottom: -1em;
}
.section-crowdspot-features {
  position: relative;
  overflow: hidden;
  padding-top: 2em;
  padding-bottom: 0em;
}
.section-crowdspot-features .gradientAnimatedBird {
  width: 47px;
  margin-bottom: -18px;
  margin-left: 0.5em;
}
@media only screen and (min-width: 600px) {
  .section-crowdspot-features .gradientAnimatedBird {
    width: 67px;
    margin-bottom: -15px;
    margin-left: 1em;
  }
}
.crowdspot-features-section {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 2em;
  padding-bottom: 2em;
}
.crowdspot-features-section--alt {
  box-shadow: 0 0 25px rgba(34,34,34,0.15) inset;
}
.crowdspot-features-section--community {
  background-image: url("img/footerbackground.svg");
}
.crowdspot-features-row {
  display: flex;
  flex-wrap: wrap;
  padding-top: 2em;
  padding-bottom: 2em;
}
@media only screen and (min-width: 800px) {
  .crowdspot-features-row--alt {
    flex-direction: row-reverse;
  }
  .crowdspot-features-row--alt .crowdspot-features__list {
    padding-right: 0em;
    padding-left: 2em;
  }
}
@media only screen and (min-width: 600px) {
  .crowdspot-features__list {
    width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .crowdspot-features__list {
    width: 35%;
    padding-right: 2em;
  }
}
.crowdspot-features__list__category-title {
  text-align: center;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 1em;
  padding-bottom: 1em;
}
.crowdspot-features__list__item {
  margin-bottom: 1em;
  display: flex;
  transition: opacity 0.5s ease;
}
@media only screen and (min-width: 600px) {
  .crowdspot-features__list__item {
    cursor: pointer;
    opacity: 0.75;
  }
}
.crowdspot-features__list__item:hover,
.crowdspot-features__list__item.active {
  opacity: 1;
}
.crowdspot-features__list__item__thumbnail {
  width: 80px;
  flex-shrink: 0;
}
.crowdspot-features__list__item__thumbnail img {
  width: 55px;
}
.crowdspot-features__list__item__text__title {
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.crowdspot-features__list__item__text__expandable {
  font-weight: 300;
  font-size: 18px;
  font-size: 1.125rem;
  display: none;
}
.crowdspot-features__images {
  display: none;
}
@media only screen and (min-width: 600px) {
  .crowdspot-features__images {
    display: block;
    min-height: 420px;
    width: 100%;
    margin-top: 2em;
  }
}
@media only screen and (min-width: 800px) {
  .crowdspot-features__images {
    margin-top: 0;
    width: 64%;
    min-height: 680px;
    overflow: visible;
  }
  .crowdspot-features__images .crowdspot-features__image,
  .crowdspot-features__images .crowdspot-features__video {
    margin-right: -35%;
  }
  .crowdspot-features__images img,
  .crowdspot-features__images video {
    width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .crowdspot-features-row--alt .crowdspot-features__image,
  .crowdspot-features-row--alt .crowdspot-features__video {
    margin-right: 0;
    margin-left: -35%;
  }
}
.crowdspot-features__video {
  position: relative;
  display: none;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(34,34,34,0.18);
}
.crowdspot-features__video:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 8vw;
  background-image: url("img/browser-head@2x.png");
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 10;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.section-signup {
  background-image: url("img/background-city-03.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #fff;
}
.section-signup h2 {
  color: #fff;
  margin-bottom: 1em;
}
.section-signup-newsletter {
  background-image: url("img/background-city-04.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 0em;
  padding-bottom: 0em;
  color: #fff;
}
.section-signup-newsletter h2 {
  color: #fff;
  margin-bottom: 0.5em;
}
.section-signup-newsletter .title-2 {
  margin-bottom: 1.5em;
}
.section-signup-newsletter .section__intro {
  margin-bottom: 1.5em;
}
.section-signup-newsletter input[type='email'] {
  width: 80%;
  max-width: 520px;
  margin-bottom: 1.5em;
  font-size: 18px;
  font-size: 1.125rem;
}
.section-why-crowdspot {
  background: linear-gradient(-45deg, #4e8146, #e26f86 90%), linear-gradient(45deg, #e26e87, #50834e 90%);
  color: #fff;
}
.section-why-crowdspot h2 {
  color: #fff;
}
.section-why-crowdspot a {
  color: #fff;
  text-decoration: underline;
}
.why-crowdspot-founders {
  margin: auto;
}
@media only screen and (min-width: 600px) {
  .why-crowdspot-founders {
    display: flex;
    justify-content: space-around;
    max-width: 900px;
  }
}
.why-crowdspot-founder {
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .why-crowdspot-founder {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 400px;
  }
}
.why-crowdspot-founder img {
  display: inline;
}
.crowdspot-founder__image {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 180px;
}
.crowdspot-founder__name {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1em;
}
.section-crowdspot-in-the-news {
  background-image: url("img/background-city-01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 0em;
  padding-bottom: 1.5em;
  text-align: center;
}
.section-crowdspot-in-the-news h2 {
  color: #fff;
}
.news-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.news-logo {
  padding: 20px;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
  z-index: 20000;
  display: none;
  text-align: center;
}
.modal .modal-fader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(34,34,34,0.8);
}
.modal .modal-container {
  position: relative;
  padding: 2em;
  background-color: #fff;
}
.modal .modal-container .pipedriveWebForms {
  height: 500px !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll !important;
}
.modal .modal-container .pipedriveWebForms iframe {
  height: 800px !important;
}
@media only screen and (min-width: 600px) {
  .modal .modal-container {
    border-radius: 6px;
    box-shadow: 2px 2px 10px rgba(34,34,34,0.5);
    width: 600px;
    margin-top: 2em;
    margin-bottom: 2em;
  }
}
.modal .close-modal {
  position: absolute;
  right: 2em;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.5s ease;
}
.modal .close-modal:hover {
  opacity: 1;
}
.modal input[type="text"],
.modal input[type="email"],
.modal input[type="tel"],
.modal textarea {
  width: 100%;
}
.modal-center {
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: stretch;
}
@media only screen and (min-width: 600px) {
  .modal-center {
    align-content: center;
    align-items: center;
  }
}
