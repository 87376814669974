/*
 * Overflow utility. Maps to regular overflow, and adds an ellipsis value.
 *
 * Synopsis:
 *
 *   overflow: <type>
 *
 * Examples:
 *
 *     overflow: auto
 *     overflow: hidden
 *     overflow: ellipsis
 *
 */

overflow()
  if arguments[0] == ellipsis
    ellipsis()
  else
    overflow: arguments
