.site-footer
	background-image url('img/footerbackground.svg')
	background-size cover
	background-repeat no-repeat
	background-position top

	padding-top 3em
	padding-bottom 4em

	color #4A4F4C

	h3
		color #4A4F4C

	a
		color #4A4F4C

		opacity 0.7
		transition 0.5s opacity ease

		&:hover
			opacity 1

.footer-column

	+above('m')
		lost-column 5/12
		lost-offset -1/12

		&.footer-column--right
		// 	lost-offset -2/12
			lost-column 4/12
			text-align right

.social-icons
	margin-top -1em

	display flex
	justify-content space-between
	max-width 150px

	+above('m')
		margin-right 0
		margin-left auto
