.slick-slider-container
	position relative

.slick-slider 
	opacity: 0
	
	// .slick-slide img
	// 	width 100%
		
	.slide
		+above('xl')
			padding-left 70px
			padding-right 70px

	&.slick-initialized 
		opacity: 1

slick-arrow-offset = 10px

.slick-prev, .slick-next
	position: absolute
	top: 50%
	transform translateY(-50%)
	left: auto
	right: slick-arrow-offset
	width: auto
	height: auto
	cursor: pointer
	opacity: 0.5
	transition opacity 0.5s
	z-index: 1000
	
	&:hover
		opacity: 1
		
	&:before
		display none
		color red
		
.slick-prev
	left: slick-arrow-offset
	right: auto