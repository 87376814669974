.section-testimonials
	background-image url('img/background-city-02.jpg')
	background-size cover
	background-repeat no-repeat
	background-position center center
	padding-top 3em
	padding-bottom 3em
	
	// color #4A4F4C
	color white
	
	// +above('m')
	// 	min-height 340px
	
	h2
		margin-top 0
		
		.gradientAnimatedBird
			margin-left 0
	
	.slick-slider
		margin-bottom 0
		
		.slick-track
			display flex
			align-items center
	
.testimonial
	text-align center
	
	+above('l')
		lost-column 10/12
		lost-offset -1/12
		
	+above('xl')
		lost-column 8/12
		lost-offset -2/12
	
	.testimonial__text
		// font-weight 700
		// fs rem(24px)
		// margin-bottom 45px