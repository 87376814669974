+html5-inputs()
  input()

// .radio
// 	radio()

// .range
// 	range(blue, 100%, 1.5em)
	
form 
	.field
		// field(left)
		max-width 100%
		
		&.error
			field-error()
		&.success
			field-success()
		&.warning
			field-warning()
		&.disabled
			field-disabled()
	
input[type="text"], input[type="email"], input[type="tel"], textarea
	// field(left)
	input()
	
	display block
	// width 100%
	margin-bottom 1em
	
	&.error
		field-error()
	&.success
		field-success()
	&.warning
		field-warning()
	&.disabled
		field-disabled()
	

label
	label()
	display none
	&.label--block
		label: block
		
// mailchimp forms

.mailchimp-form
	position relative

.mc-text-field
	margin-left auto
	margin-right auto
	text-align center
	
.mc-label
	// position absolute
	// bottom -1em
	// transform translateX(-50%)
	margin-bottom 1em
	