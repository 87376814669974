.section-signup
	background-image url('img/background-city-03.jpg')
	background-size cover
	background-repeat no-repeat
	background-position center center

	padding-top 1em
	padding-bottom 1em

	color white

	h2
		color white
		margin-bottom: 1em
