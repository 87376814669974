.section-crowdspot-in-the-news
	background-image url('img/background-city-01.jpg')
	background-size cover
	background-repeat no-repeat
	background-position center center

	padding-top 0em
	padding-bottom 1.5em

	text-align center

	h2
		color white

.news-logos
	display flex
	justify-content center
	align-items center
	flex-wrap wrap

.news-logo
	// display inline-block
	padding 20px
