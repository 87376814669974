#map
	display block
	
	.roads, .features, .mainroad, .marker, .visualise, .decisionbird, #logotype, .logobird, .mapgradient, .introTitles
		opacity 0

.safari, .mobile
	#map
		.roads, .features, .mainroad, #logotype, .logobird, .mapgradient
			opacity 1

// .gradientanimatedbird1
// 	fill linear-gradient(230deg, #24dcac, #dc246f);
// background-size: 400% 400%;

.main-intro
	position relative
	
	.section__subtitle
		display block
		position absolute
		opacity 0
		bottom 100%
		
		.title-2
			text-align center
			fs: rem(16px)
			
		+above(320px)
			bottom 5%
			left 0
			right 0
			
		+above('m')
			bottom 8%
			.title-2
				fs: rem(24px)
			
		+above('xl')
			bottom 22%