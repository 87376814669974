$sprite-base-font-size = 10px

.sprite {
    font-size: $sprite-base-font-size;
    vertical-align: middle;
    line-height: 1;
    position: relative;
    top: 1px;
    display: inline-block;
}

.sprite {
    background-repeat: no-repeat;
    background-image: url(img/iconsprites/sprite.svg);
    background-size: 224em*(1/$sprite-base-font-size) 2738em*(1/$sprite-base-font-size);
}

.no-svg .sprite {
    background-image: url(img/iconsprites/sprite.png);
}

.sprite-circle_green {
    background-position: 0em*(1/$sprite-base-font-size) 0em*(1/$sprite-base-font-size);
    width: 224em*(1/$sprite-base-font-size);
    height: 224em*(1/$sprite-base-font-size);
}

.sprite-circle_grey {
    background-position: 0em*(1/$sprite-base-font-size) -224em*(1/$sprite-base-font-size);
    width: 224em*(1/$sprite-base-font-size);
    height: 224em*(1/$sprite-base-font-size);
}

.sprite-circle_red {
    background-position: 0em*(1/$sprite-base-font-size) -448em*(1/$sprite-base-font-size);
    width: 224em*(1/$sprite-base-font-size);
    height: 224em*(1/$sprite-base-font-size);
}

.sprite-close-button {
    background-position: 0em*(1/$sprite-base-font-size) -672em*(1/$sprite-base-font-size);
    width: 33em*(1/$sprite-base-font-size);
    height: 33em*(1/$sprite-base-font-size);
}

.sprite-contact-email {
    background-position: 0em*(1/$sprite-base-font-size) -705em*(1/$sprite-base-font-size);
    width: 46em*(1/$sprite-base-font-size);
    height: 46em*(1/$sprite-base-font-size);
}

.sprite-contact-linkedin {
    background-position: 0em*(1/$sprite-base-font-size) -751em*(1/$sprite-base-font-size);
    width: 46em*(1/$sprite-base-font-size);
    height: 46em*(1/$sprite-base-font-size);
}

.sprite-contact-twitter {
    background-position: 0em*(1/$sprite-base-font-size) -797em*(1/$sprite-base-font-size);
    width: 46em*(1/$sprite-base-font-size);
    height: 46em*(1/$sprite-base-font-size);
}

.sprite-crowdspot-logo {
    background-position: 0em*(1/$sprite-base-font-size) -843em*(1/$sprite-base-font-size);
    width: 178em*(1/$sprite-base-font-size);
    height: 28em*(1/$sprite-base-font-size);
}

.sprite-facebook_logo_big {
    background-position: 0em*(1/$sprite-base-font-size) -871em*(1/$sprite-base-font-size);
    width: 27em*(1/$sprite-base-font-size);
    height: 26em*(1/$sprite-base-font-size);
}

.sprite-facebook_logo_footer {
    background-position: 0em*(1/$sprite-base-font-size) -897em*(1/$sprite-base-font-size);
    width: 16em*(1/$sprite-base-font-size);
    height: 16em*(1/$sprite-base-font-size);
}

.sprite-facebook_logo_footer_white {
    background-position: 0em*(1/$sprite-base-font-size) -913em*(1/$sprite-base-font-size);
    width: 16em*(1/$sprite-base-font-size);
    height: 16em*(1/$sprite-base-font-size);
}

.sprite-green_arrow {
    background-position: 0em*(1/$sprite-base-font-size) -929em*(1/$sprite-base-font-size);
    width: 52em*(1/$sprite-base-font-size);
    height: 78em*(1/$sprite-base-font-size);
}

.sprite-grey_arrow {
    background-position: 0em*(1/$sprite-base-font-size) -1007em*(1/$sprite-base-font-size);
    width: 52em*(1/$sprite-base-font-size);
    height: 78em*(1/$sprite-base-font-size);
}

.sprite-instagram_logo_big {
    background-position: 0em*(1/$sprite-base-font-size) -1085em*(1/$sprite-base-font-size);
    width: 28em*(1/$sprite-base-font-size);
    height: 26em*(1/$sprite-base-font-size);
}

.sprite-instagram_logo_footer {
    background-position: 0em*(1/$sprite-base-font-size) -1111em*(1/$sprite-base-font-size);
    width: 17em*(1/$sprite-base-font-size);
    height: 16em*(1/$sprite-base-font-size);
}

.sprite-instagram_logo_footer_white {
    background-position: 0em*(1/$sprite-base-font-size) -1127em*(1/$sprite-base-font-size);
    width: 17em*(1/$sprite-base-font-size);
    height: 16em*(1/$sprite-base-font-size);
}

.sprite-instagram_logo_posts {
    background-position: 0em*(1/$sprite-base-font-size) -1143em*(1/$sprite-base-font-size);
    width: 38em*(1/$sprite-base-font-size);
    height: 36em*(1/$sprite-base-font-size);
}

.sprite-linkedin {
    background-position: 0em*(1/$sprite-base-font-size) -1179em*(1/$sprite-base-font-size);
    width: 15.27em*(1/$sprite-base-font-size);
    height: 15.651em*(1/$sprite-base-font-size);
}

.sprite-logo-cityofmelbourne {
    background-position: 0em*(1/$sprite-base-font-size) -1195em*(1/$sprite-base-font-size);
    width: 33em*(1/$sprite-base-font-size);
    height: 31em*(1/$sprite-base-font-size);
}

.sprite-logo-cityofportphillip {
    background-position: 0em*(1/$sprite-base-font-size) -1226em*(1/$sprite-base-font-size);
    width: 39em*(1/$sprite-base-font-size);
    height: 39em*(1/$sprite-base-font-size);
}

.sprite-logo-facebook {
    background-position: 0em*(1/$sprite-base-font-size) -1265em*(1/$sprite-base-font-size);
    width: 30em*(1/$sprite-base-font-size);
    height: 31em*(1/$sprite-base-font-size);
}

.sprite-logo-instagram {
    background-position: 0em*(1/$sprite-base-font-size) -1296em*(1/$sprite-base-font-size);
    width: 30em*(1/$sprite-base-font-size);
    height: 30em*(1/$sprite-base-font-size);
}

.sprite-logo-linkedin {
    background-position: 0em*(1/$sprite-base-font-size) -1326em*(1/$sprite-base-font-size);
    width: 30em*(1/$sprite-base-font-size);
    height: 30em*(1/$sprite-base-font-size);
}

.sprite-logo-parksvictoria {
    background-position: 0em*(1/$sprite-base-font-size) -1356em*(1/$sprite-base-font-size);
    width: 41em*(1/$sprite-base-font-size);
    height: 26em*(1/$sprite-base-font-size);
}

.sprite-logo-plan {
    background-position: 0em*(1/$sprite-base-font-size) -1382em*(1/$sprite-base-font-size);
    width: 75em*(1/$sprite-base-font-size);
    height: 29em*(1/$sprite-base-font-size);
}

.sprite-logo-tac {
    background-position: 0em*(1/$sprite-base-font-size) -1411em*(1/$sprite-base-font-size);
    width: 53em*(1/$sprite-base-font-size);
    height: 25em*(1/$sprite-base-font-size);
}

.sprite-logo-theuniversityofmelbourne {
    background-position: 0em*(1/$sprite-base-font-size) -1436em*(1/$sprite-base-font-size);
    width: 39em*(1/$sprite-base-font-size);
    height: 37em*(1/$sprite-base-font-size);
}

.sprite-logo-twitter {
    background-position: 0em*(1/$sprite-base-font-size) -1473em*(1/$sprite-base-font-size);
    width: 30em*(1/$sprite-base-font-size);
    height: 25em*(1/$sprite-base-font-size);
}

.sprite-logo-victoria {
    background-position: 0em*(1/$sprite-base-font-size) -1498em*(1/$sprite-base-font-size);
    width: 55em*(1/$sprite-base-font-size);
    height: 31em*(1/$sprite-base-font-size);
}

.sprite-logo-vta {
    background-position: 0em*(1/$sprite-base-font-size) -1529em*(1/$sprite-base-font-size);
    width: 53em*(1/$sprite-base-font-size);
    height: 31em*(1/$sprite-base-font-size);
}

.sprite-post-arrow-left {
    background-position: 0em*(1/$sprite-base-font-size) -1560em*(1/$sprite-base-font-size);
    width: 10em*(1/$sprite-base-font-size);
    height: 15em*(1/$sprite-base-font-size);
}

.sprite-post-facebook-share {
    background-position: 0em*(1/$sprite-base-font-size) -1575em*(1/$sprite-base-font-size);
    width: 16em*(1/$sprite-base-font-size);
    height: 16em*(1/$sprite-base-font-size);
}

.sprite-projects-comments-icon {
    background-position: 0em*(1/$sprite-base-font-size) -1591em*(1/$sprite-base-font-size);
    width: 69em*(1/$sprite-base-font-size);
    height: 64em*(1/$sprite-base-font-size);
}

.sprite-projects-spots-icon {
    background-position: 0em*(1/$sprite-base-font-size) -1655em*(1/$sprite-base-font-size);
    width: 39em*(1/$sprite-base-font-size);
    height: 58em*(1/$sprite-base-font-size);
}

.sprite-projects-supports-icon {
    background-position: 0em*(1/$sprite-base-font-size) -1713em*(1/$sprite-base-font-size);
    width: 55em*(1/$sprite-base-font-size);
    height: 85em*(1/$sprite-base-font-size);
}

.sprite-red_arrow {
    background-position: 0em*(1/$sprite-base-font-size) -1798em*(1/$sprite-base-font-size);
    width: 52em*(1/$sprite-base-font-size);
    height: 78em*(1/$sprite-base-font-size);
}

.sprite-slider-arrow-left {
    background-position: 0em*(1/$sprite-base-font-size) -1876em*(1/$sprite-base-font-size);
    width: 69em*(1/$sprite-base-font-size);
    height: 101em*(1/$sprite-base-font-size);
}

.sprite-slider-arrow-left-dark {
    background-position: 0em*(1/$sprite-base-font-size) -1977em*(1/$sprite-base-font-size);
    width: 51em*(1/$sprite-base-font-size);
    height: 50em*(1/$sprite-base-font-size);
}

.sprite-slider-arrow-left-mobile {
    background-position: 0em*(1/$sprite-base-font-size) -2027em*(1/$sprite-base-font-size);
    width: 50em*(1/$sprite-base-font-size);
    height: 65em*(1/$sprite-base-font-size);
}

.sprite-slider-arrow-right {
    background-position: 0em*(1/$sprite-base-font-size) -2092em*(1/$sprite-base-font-size);
    width: 69em*(1/$sprite-base-font-size);
    height: 101em*(1/$sprite-base-font-size);
}

.sprite-slider-arrow-right-dark {
    background-position: 0em*(1/$sprite-base-font-size) -2193em*(1/$sprite-base-font-size);
    width: 51em*(1/$sprite-base-font-size);
    height: 50em*(1/$sprite-base-font-size);
}

.sprite-slider-arrow-right-mobile {
    background-position: 0em*(1/$sprite-base-font-size) -2243em*(1/$sprite-base-font-size);
    width: 50em*(1/$sprite-base-font-size);
    height: 64em*(1/$sprite-base-font-size);
}

.sprite-slider-dot {
    background-position: 0em*(1/$sprite-base-font-size) -2307em*(1/$sprite-base-font-size);
    width: 10em*(1/$sprite-base-font-size);
    height: 10em*(1/$sprite-base-font-size);
}

.sprite-slider-dot-current {
    background-position: 0em*(1/$sprite-base-font-size) -2317em*(1/$sprite-base-font-size);
    width: 10em*(1/$sprite-base-font-size);
    height: 10em*(1/$sprite-base-font-size);
}

.sprite-solutions-asset-monitoring {
    background-position: 0em*(1/$sprite-base-font-size) -2327em*(1/$sprite-base-font-size);
    width: 42em*(1/$sprite-base-font-size);
    height: 52em*(1/$sprite-base-font-size);
}

.sprite-solutions-community-engagement {
    background-position: 0em*(1/$sprite-base-font-size) -2379em*(1/$sprite-base-font-size);
    width: 77em*(1/$sprite-base-font-size);
    height: 57em*(1/$sprite-base-font-size);
}

.sprite-solutions-environmental-management {
    background-position: 0em*(1/$sprite-base-font-size) -2436em*(1/$sprite-base-font-size);
    width: 57em*(1/$sprite-base-font-size);
    height: 56em*(1/$sprite-base-font-size);
}

.sprite-solutions-internation-development {
    background-position: 0em*(1/$sprite-base-font-size) -2492em*(1/$sprite-base-font-size);
    width: 55em*(1/$sprite-base-font-size);
    height: 79em*(1/$sprite-base-font-size);
}

.sprite-solutions-media {
    background-position: 0em*(1/$sprite-base-font-size) -2571em*(1/$sprite-base-font-size);
    width: 59em*(1/$sprite-base-font-size);
    height: 52em*(1/$sprite-base-font-size);
}

.sprite-solutions-urban-planning {
    background-position: 0em*(1/$sprite-base-font-size) -2623em*(1/$sprite-base-font-size);
    width: 91em*(1/$sprite-base-font-size);
    height: 54em*(1/$sprite-base-font-size);
}

.sprite-x_close_desktop {
    background-position: 0em*(1/$sprite-base-font-size) -2677em*(1/$sprite-base-font-size);
    width: 40em*(1/$sprite-base-font-size);
    height: 40em*(1/$sprite-base-font-size);
}

.sprite-x_close_mobile {
    background-position: 0em*(1/$sprite-base-font-size) -2717em*(1/$sprite-base-font-size);
    width: 21em*(1/$sprite-base-font-size);
    height: 21em*(1/$sprite-base-font-size);
}

