.button
	button #F6F6F6
	box-shadow 1px 1px 2px rgba(black, 0.20)
	transition box-shadow ease 0.3s
	fs rem(14px)
	font-weight 600
	
	&:hover
		background-color white
		box-shadow 0 0 4px rgba(black, 0.20)
		text-decoration none
		
.button--glossy
	// glossy-button red 'large'
	glossy-button(color: orange, size: 18, textColor: white)
	hover-pop: 1.2 3deg true
	transition all ease 0.2s
	
.button--block
	display block