// ------
// Tables
// ------

// Mixin: Table
//
// Ported directly from Twitter Bootstrap, as they did an excellent job with
// the tables. Take three fairly self-explanitory arguments, all boolean. 1st
// makes the table bordered, 2nd adds zebra striping, and the 3rd indiated
// whether the table is condensed or not.
//
// ex. table()
// ex. table(striped: true)

table($border = true, $striped = true, $condensed = false)
  max-width: 100%
  border-collapse: collapse
  border-spacing: 0
  width: 100%
  margin-bottom: 18px

  th, td
    padding: 8px
    line-height: 18px
    text-align: left
    vertical-align: top
    border-top: 1px solid #ddd

  th
    font-weight: bold

  thead th
    vertical-align: bottom

  thead:first-child tr th
  thead:first-child tr td
    border-top: 0

  tbody + tbody
    border-top: 2px solid #ddd

  if $condensed
    th, td
      padding: 4px 5px

  if $border
    border: 1px solid #ddd
    border-collapse: separate
    *border-collapse: collapsed
    border-radius: 4px

    th + th, td + td, th + td, td + th
      border-left: 1px solid #ddd

    thead:first-child tr:first-child th
    tbody:first-child tr:first-child th
    tbody:first-child tr:first-child td
      border-top: 0

    thead:first-child tr:first-child th:first-child
    tbody:first-child tr:first-child td:first-child
      border-radius: 4px 0 0 0

    thead:first-child tr:first-child th:last-child
    tbody:first-child tr:first-child td:last-child
      border-radius: 0 4px 0 0

    thead:last-child tr:last-child th:first-child
    tbody:last-child tr:last-child td:first-child
      border-radius: 0 0 0 4px

    thead:last-child tr:last-child th:last-child
    tbody:last-child tr:last-child td:last-child
      border-radius: 0 0 4px 0

  if $striped
    tbody tr:nth-child(odd) td
    tbody tr:nth-child(odd) th
      background-color: #f9f9f9

    tbody tr:hover td
    tbody tr:hover th
      background-color: #f5f5f5

// Additive Mixin: Tables
//
// WARNING: Creates classes in your css and styles them - not to be used inside
// an element.
//
// Makes tables look awesome by default. Highly recommended if you have tables
// on your site at all.

tables()
  table
    table()
