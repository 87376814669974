.section-crowdspot-features
	// background-image linear-gradient(-223deg, rgba(255,255,255,0.50) 0%, rgba(0,255,39,0.50) 100%), radial-gradient(circle at top left, #284EF6, #FF0000);
	// background-image linear-gradient(180deg, #4A4F4C 0%, black 100%)
	// background-color black
	// background-color #C7C7C7
	position relative
	// background linear-gradient(-45deg, rgba(#4E8146, 1), rgba(#E26F86, 1) 90%), linear-gradient(45deg, rgba(#E26E87, 1), rgba(#50834E, 1) 90%)
	// color white
	overflow hidden

	padding-top 2em
	padding-bottom 0em

	.gradientAnimatedBird
		width 47px
		margin-bottom -18px
		margin-left 0.5em

		+above('m')
			width 67px
			margin-bottom -15px
			margin-left 1em

	// h2
	// 	color white

.crowdspot-features-section
	// background-image url('img/footerbackground.svg')
	background-image none
	background-size cover
	background-repeat no-repeat
	background-position top
	padding-top 2em
	padding-bottom 2em
	
.crowdspot-features-section--alt
	box-shadow 0 0 25px rgba(black, 0.15) inset
			
.crowdspot-features-section--community
	background-image url('img/footerbackground.svg')
	
.crowdspot-features-row
	display flex
	// align-items center
	flex-wrap wrap
	padding-top 2em
	padding-bottom 2em
	
.crowdspot-features-row--alt
	
	+above('l')
		flex-direction row-reverse
		
		.crowdspot-features__list
			padding-right 0em
			padding-left 2em
	
.crowdspot-features__list
	+above('m')
		width 100%
		
	+above('l')
		width 35%
		padding-right 2em

.crowdspot-features__list__category-title
	text-align center
	fs rem(24px)
	font-weight 700
	padding-top 1em
	padding-bottom 1em

.crowdspot-features__list__item
	margin-bottom 1em
	display flex

	transition opacity 0.5s ease

	+above('m')
		cursor pointer
		opacity 0.75

	&:hover, &.active
		opacity 1

.crowdspot-features__list__item__thumbnail
	width 80px
	flex-shrink 0

	img
		width 55px

.crowdspot-features__list__item__text__title
	font-weight 700
	fs rem(18px)
	padding-top 0.5em
	padding-bottom 0.5em

.crowdspot-features__list__item__text__expandable
	font-weight 300
	fs rem(18px)
	display none

.crowdspot-features__images
	display none

	+above('m')
		display block
		min-height 420px
		width 100%
		margin-top 2em
		
	+above('l')
		margin-top 0
		width 64%
		min-height 680px
		overflow visible
		
		.crowdspot-features__image, .crowdspot-features__video
			margin-right -35%
			
		img, video
			width 100%

.crowdspot-features-row--alt
	
	+above('l')
		.crowdspot-features__image, .crowdspot-features__video
			margin-right 0
			margin-left -35%
			
.crowdspot-features__video
	position relative
	display none
	overflow hidden
	border-radius 5px
	box-shadow 2px 2px 5px rgba(black, 0.18)

	&:before
		content ''
		position absolute
		width 100%
		height 8vw
		background-image url('img/browser-head@2x.png')
		background-repeat no-repeat
		background-size 100%
		z-index 10
		border-top-left-radius 5px
		border-top-right-radius 5px