.pace
	pointer-events none
	user-select none
	
body
	overflow hidden
	
.fade-in-on-load
	opacity 0
	transition opacity 1.5s

.pace-done
	overflow scroll
		
	.fade-in-on-load
		opacity 1

.pace-inactive
	display none

.pace .pace-progress
	background #FF0000
	position fixed
	z-index 3000
	top 0
	right 100%
	width 100%
	height 5px