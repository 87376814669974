// ---------
// Gradients
// ---------

// Mixin: Gradient
//
// Takes one color and outputs a vertical gradient between the offset by 10% in
// either direction, offset precentage customizable via `strength` param. Or
// takes two colors and outputs a vertical gradient that fades between the two
// colors.
//
// ex. gradient: red blue
// ex. gradient: red

gradient($color1, $color2 = null, $strength = 10%)
  // shift args if one color mode
  if (typeof($color2) is 'unit')
    $strength = $color2
    $color2 = null

  $start = $color2 ? $color1 : lighten($color1, $strength)
  $end = $color2 ? $color2 : darken($color1, $strength)
  background: $start
  background: $end linear-gradient(180deg, $start, $end) repeat-x

// Mixin: Image Gradient
//
// Exactly the same as the gradient mixin, but overlays an image to give it
// more texture.
//
// ex. image-gradient: red pattern.png
// ex. image-gradient: red pattern.png 30%
// ex. image-gradient: red blue pattern.png

image-gradient($color1, $color2 = null, $image-path = 'noise.png', $strength = 10%)
  // shift args if one color mode
  if (typeof($color2) == 'string')
    $strength = $image-path if (typeof($image-path) == 'unit')
    $image-path = $color2
    $color2 = null

  $start = $color2 ? $color1 : lighten($color1, $strength)
  $end = $color2 ? $color2 : darken($color1, $strength)
  background: $start
  background: url($img-path $image-path) repeat, $end linear-gradient(180deg, $start, $end) repeat-x
