.section-signup-newsletter
	background-image url('img/background-city-04.jpg')
	background-size cover
	background-repeat no-repeat
	background-position center center

	padding-top 0em
	padding-bottom 0em

	color white

	h2
		color white
		margin-bottom: 0.5em

	.title-2
		margin-bottom: 1.5em

	.section__intro
		margin-bottom: 1.5em

	input[type='email']
		width 80%
		max-width 520px
		margin-bottom 1.5em
		fs: rem(18px)
