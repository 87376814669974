.section-crowdsport-projects
	// background-color #CFD2C1
	// background-image linear-gradient(0deg, #CFD2C1 0%, white 50%, white 100%)
	// background-image linear-gradient(180deg, #9A9A9A 0%, white 100%)
	background-image linear-gradient(180deg, #4A4F4C 0%, #4A4F4C 45%, #CFD2C1 45%, #CFD2C1 100%)
	// background-image url("img/background-map.svg"), linear-gradient(180deg, rgba(white, 0.5) 0%, white 100%)
	// background-image url("img/background-map.svg")
	// background-size cover
	// background-repeat no-repeat
	// background-position center center
	
	padding-top 1em
		
	+above('m')
		padding-top 4em
		background-image linear-gradient(180deg, #4A4F4C 0%, #4A4F4C 63%, #CFD2C1 63%, #CFD2C1 100%)
		
	.gradientAnimatedBird
		width 47px
		margin-bottom -18px
		margin-left 0.5em
		
		+above('m')
			width 67px
			margin-bottom -15px
			margin-left 1em
		
	h2
		color white
	
	
.projects-tabs
	lost-utility clearfix
	margin-bottom 2em
	margin-left auto
	margin-right auto
	max-width 940px
	display flex
	
	// align-content space-between
	
// .project-tab + .project-tab
// 	margin-left 1em
// 	padding 1em
	
.projects-tab
	// lost-column 1/5
	text-align center
	fs rem(14px)
	line-height 1.25
	opacity 0.5
	transition opacity 0.5s ease
	color white
	
	padding 5px
	
	flex: 1 1 0
	
	// +above('m')
	// 	lost-column 1/5
	
	&.active, &:hover
		opacity 1
		cursor pointer
		
		.projects-tab-images
			border 4px solid white
		
		// .slick-slider--projects__tab-title
		// 	font-weight 600

.slick-slider--projects__tab-title
	display none
	
	+above('m')
		display block
	
.projects-tab-images
	max-width 105px
	width 100%
	margin auto
	border-radius 50%
	box-shadow 2px 2px 4px 0 rgba(black, 0.18)
	margin-bottom 1em
	border 4px solid transparent
	transition border 0.5s ease

.slick-slider--projects
	margin-bottom 0
	
.project-slide__container
	margin-left auto
	margin-right auto
	max-width 940px
	
.project-slide__image
	margin-left auto
	margin-right auto
	
	+above('m')
		margin-bottom 3em

.project-slide__details
	+above('m')
		lost-column 3/6

.project-slide__title
	text-align center
	font-weight 600
	fs rem(24px)
	color #666
	margin-bottom 1.5em
	display none
	
	&.project-slide__title--mobile
		color white
		display block
	
	+above('m')
		display block
		
		&.project-slide__title--mobile
			display none
	
	

.project-slide__stat
	lost-column 1/3
	text-align center
	
	+above('m')
		lost-column 1/6

.project-slide__stat__icon
	height 50px
	position relative
	margin-bottom 0.5em
	
	+above('m')
		height 100px

	i
		position absolute
		top auto
		bottom 0
		left 50%
		transform translateX(-50%)
		font-size 5px
		
		+above('m')
			font-size 10px

.project-slide__stat__label
	color #D12325
	font-weight 700
	fs rem(18px)
	margin-bottom 0.3em
	
.project-slide__stat__value
	fs rem(24px)
	font-weight 700
	
.project-slide__project-link
	lost-utility clearfix
	
.slick-slider--project-sub-slider
	margin-bottom 1.5em

.slick-dots
	bottom -1em