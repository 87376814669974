.modal-open
	overflow hidden

.modal
	position fixed
	left 0
	right 0
	top 0
	bottom 0
	overflow scroll
	z-index 20000
	display none
	
	text-align center
	
	.modal-fader
		position fixed
		left 0
		right 0
		top 0
		bottom 0
		background-color rgba(black, 0.8)
	
	.modal-container
		position relative
		
		// max-height 1200px
		// overflow scroll
		
		padding 2em
		
		background-color white
		// background-color #CFD2C1
		// background-image url('img/modal-signup-background.svg')
		// background-size cover
		// background-position center center
		
		.pipedriveWebForms
			// max-height 1200px
			
			height 500px!important
			
			
			-webkit-overflow-scrolling: touch;
			  overflow-y: scroll!important;
				
			iframe
			// 	overflow scroll!important
				height 800px!important
		
		+above('m')
			border-radius 6px
			box-shadow 2px 2px 10px rgba(black, 0.5)
			width 600px
			margin-top 2em
			margin-bottom 2em
		
	
	.close-modal
		position absolute
		right 2em
		cursor pointer
		opacity 0.6
		transition opacity 0.5s ease
		
		&:hover
			opacity 1
	
	input[type="text"], input[type="email"], input[type="tel"], textarea
		width 100%

.modal-center
	min-height 100%
	width 100%
	display flex
	justify-content center
	align-content stretch
	
	+above('m')
		align-content center
		align-items center
	