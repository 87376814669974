$slick-font-path            ?= "./fonts/"
$slick-font-family          ?= "slick"
$slick-loader-path          ?= "./img/"
$slick-arrow-color          ?= white
$slick-dot-color            ?= black
$slick-dot-color-active     ?= $slick-dot-color
$slick-prev-character       ?= '\2190'
$slick-next-character       ?= '\2192'
$slick-dot-character        ?= '\2022'
$slick-dot-size             ?= 15px
$slick-opacity-default      ?= .75
$slick-opacity-on-hover     ?= 1
$slick-opacity-not-active   ?= .25


slick-image-url($url)
   url($slick-loader-path + $url);


slick-font-url($url)
  url($slick-font-path + $url);

/* Slider */
.slick-slider {
  position relative
  display block
  box-sizing border-box
  -webkit-touch-callout none
  user-select none
  touch-action pan-y
  -webkit-tap-highlight-color transparent
}

.slick-list {
  position relative
  overflow hidden
  display block
  margin 0
  padding 0

  &:focus {
    outline none
  }

  .slick-loading & {
    background #fff slick-image-url("ajax-loader.gif") center center no-repeat
  }

  &.dragging {
    cursor pointer
    cursor hand
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform translate3d(0, 0, 0)
}

.slick-track {
  position relative
  left 0
  top 0
  display block

  &:before,
  &:after {
    content ""
    display table
  }

  &:after {
    clear both
  }

  .slick-loading & {
    visibility hidden
  }
}
.slick-slide {
  float left
  height 100%
  min-height 1px
  [dir="rtl"] & {
    float right
  }
  img {
      display block
  }
  &.slick-loading img {
    display none
  }

  display none

  &.dragging img {
    pointer-events none
  }

  .slick-initialized & {
    display block
  }

  .slick-loading & {
    visibility hidden
  }

  .slick-vertical & {
    display block
    height auto
    border 1px solid transparent
  }
}

/* Icons */
if $slick-font-family == "slick" {
  @font-face {
    font-family:"slick"
    src slick-font-url("slick.eot")
    src slick-font-url("slick.eot?#iefix") format("embedded-opentype"),
        slick-font-url("slick.woff") format("woff"),
        slick-font-url("slick.ttf") format("truetype"),
        slick-font-url("slick.svg#slick") format("svg")
    font-weight normal
    font-style normal
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  position absolute
  display block
  height 20px
  width 20px
  line-height 0
  font-size 0
  cursor pointer
  background transparent
  color transparent
  top 50%
  margin-top -10px
  padding 0
  border none
  outline none
  &:hover,
  &:focus {
    outline none
    background transparent
    color transparent
    &:before {
      opacity $slick-opacity-on-hover
    }
  }
  &.slick-disabled:before {
    opacity $slick-opacity-not-active
  }
}

.slick-prev:before, .slick-next:before {
  font-family $slick-font-family
  font-size 20px
  line-height 1
  color $slick-arrow-color
  opacity $slick-opacity-default
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
}

.slick-prev {
  left -25px
  [dir="rtl"] & {
    left  auto
    right -25px
  }
  &:before {
    content $slick-prev-character
    [dir="rtl"] & {
      content $slick-next-character
    }
  }
}
.slick-next {
  right -25px
  [dir="rtl"] & {
    left  -25px
    right auto
  }
  &:before {
    content $slick-next-character
    [dir="rtl"] & {
      content $slick-prev-character
    }
  }
}

/* Dots */

.slick-slider {
  margin-bottom 30px
}
.slick-dots {
  position absolute
  bottom -45px
  list-style none
  display block
  text-align center
  padding 0
  width 100%

  li {
    position relative
    display inline-block
    height 20px
    width 20px
    margin 0 5px
    padding 0
    cursor pointer

    button {
      border 0
      background transparent
      display block
      height 20px
      width 20px
      outline none
      line-height 0
      font-size 0
      color transparent
      padding 5px
      cursor pointer
      &:hover, &:focus {
        outline none
        &:before {
          opacity $slick-opacity-on-hover
        }
      }

      &:before {
        position absolute
        top 0
        left 0
        content $slick-dot-character
        width 20px
        height 20px
        font-family $slick-font-family
        font-size $slick-dot-size
        line-height 20px
        text-align center
        color $slick-dot-color
        opacity $slick-opacity-not-active
        -webkit-font-smoothing antialiased
        -moz-osx-font-smoothing grayscale
      }
    }

    &.slick-active button:before {
      color $slick-dot-color-active
      opacity $slick-opacity-default
    }
  }
}