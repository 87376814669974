.section
	padding-top 4em
	padding-bottom 4em
	
	+above('m')
		padding-top 110px
		padding-bottom 110px
	
.section__subtitle
	text-align center
	
	+above('m')
		lost-column 8/12
		lost-offset -2/12
		
.section__intro
	margin-bottom 2em
	text-align center
	
	+above('m')
		lost-column 10/12
		lost-offset -1/12