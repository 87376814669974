/*
 * border: <color>
 * border: ...
 */

border(color, args...)
  if color is a 'color'
    border: 1px solid color args
  else
    border: arguments
