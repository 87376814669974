// @lost flexbox flex;
// @lost flexbox no-flex;

.container
	lost-utility clearfix
	lost-center 1200px 20px

.column
	+above('m')
		lost-column 1/2
		
.column-offset
	+above('m')
		lost-offset -1/2
		
.column-3
	+above('m')
		lost-column 1/3

.waffle-grid
	margin 30px 0
	lost-utility clearfix	
	
.waffle-grid-item
	padding 20px
	gradient green
	lost-waffle 1/3
	text-align center
	
	&.waffle-grid-item--nopadding
		lost-waffle 1/3 3 0

.column-no-gutters
	gradient red
	height 300px
	+above('m')
		lost-column 1/2 2 0
		
.grid-row
	lost-utility clearfix
		
.align-parent
	// reset|horizontal|vertical|top-left|top-center|top|top-right|middle-left|left|middle-center|center|middle-right|right|bottom-left|bottom-center|bottom|bottom-right
	lost-align center
	size 100% 100vh
	gradient: red
	// background blue
	color white
	// hover-lighten()

.align-child
	+above('m')
		width 500px
	

.row-container
	height 100vh

.row
	lost-align center
	lost-utility clearfix
	lost-row 1/3 0
	gradient: green blue


	
.grid-asymetrical
	width 100%
	lost-utility clearfix
	
.grid-asymetrical .grid-item
	text-align center
	height 100px
	background red
	lost-column 1/4 0
	height 50vh
	
.grid-asymetrical .grid-item:nth-child(2)
	lost-column 1/2 0
	

.grid-move
	.grid-item
		background red
		height 100px
		lost-column 1/3
		
	.move-grid-item
		lost-move 1/3
		